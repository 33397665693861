import React, { useState } from "react";
import { motion } from "framer-motion";
import { Toaster, toast } from "react-hot-toast";
import emailjs from '@emailjs/browser'
import Modal from "../../components/Modal";
import { AiOutlineClose } from "react-icons/ai";


function AchivementFr() {

	const [isModalOpen, setIsModalOpen] = useState(false);
	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setEmail("");
		setFirstName("");
		setMessage("");
		setCompany("");
		setName("");
		setSubject("");
	};

	const [name, setName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");


	const handleSubmit = (e) => {
		e.preventDefault()
		//keys and services
		const templateId ='template_k06u98r'
		const publicKey = 'vRKpOxPaAllPvKD00'
		const serviceId = 'service_1icj9w8' 
	
		//create new object
	
		const templateParams ={
		  to_name:'Africa55Durable Acheivement request',
		  from_name:name,
		  from_firstName:firstName,
		  from_email:email,
		  from_company:company,
		  subject:subject,
		  message:message,
  
		}
		
		//send theEmail
		emailjs.send(serviceId, templateId, templateParams, publicKey)
		.then((response)=>{
		  console.log('Message successfully sent', response)
		  setEmail('')
		  setFirstName('')
		  setMessage('')
		  setCompany('')
		  setName('')
		  setSubject('')
  
		})
		.catch((error)=>{
		  console.error('Error sending', error)
		})
  
  
	
		//////////
		const refreshToast = toast.loading("Your message has been sent. We will shortly contact you");
				toast.success("Your message has been sent. We will shortly contact you", {
					id: refreshToast,
			duration: 7000,
				});
	
		  /// navigate to
	  }
  

	return (
		<>
			<div className="flex flex-1 flex-col w-full">
            <div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 items-center  ">
					<div className=" bg-emerald-800 p-6 sm:max-w-40 sm:h-[400px] ">
						<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
					</div>
					<img
						src="/logo.png"
						alt=""
					className="h-[354px] w-[371px]  object-cover absolute ml-10 "
					/>
				</motion.div>
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black justify-center items-center">
					<p className=" text-2xl w-fit text-orange-500">
					Valable uniquement sur demande
					</p>
					<p onClick={openModal} className="flex bg-green-400 cursor-pointer text-white hover:bg-teal-600 px-4 py-2 rounded-full mt-5 text-lg">Request</p>
					
                   
				</motion.div>
			</div>
			<div className=" hidden sm:flex bg-cyan-200 flex-1 w-full h-1 text-cyan-200">gtrr </div>
            </div>
			{/*--mobile screen--*/}
			<div className="flex flex-1 flex-col w-full">
            <div className="sm:hidden flex flex-col flex-1  mt-6 px-24 sm:space-y-3 bg-white p-4 ">
				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 justify-center  ">
					
					<img
						src="/logo.png"
						alt=""
						className=" flex flex-1 justify-centerh-[95px] w-[100px] object-cover  my-10 "
					/>
				</motion.div>
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black justify-center items-center mt-6">
					<p className=" text-2xl text-nowrap text-orange-500">
					Disponible à la demande
					</p>
					<p onClick={openModal} className="flex bg-green-400 cursor-pointer text-white hover:bg-teal-600 px-4 py-2 rounded-full mt-5 text-lg">Request</p>
					
                   
				</motion.div>
			</div>
			<div className=" hidden sm:flex bg-cyan-200 flex-1 w-full h-1 text-cyan-200">gtrr </div>
            </div>


			<Modal isOpen={isModalOpen} closeModal={closeModal}>
			<p className="flex flex-1 px-4 justify-between">
					<p></p>
					<p onClick={closeModal} className=" flex px-2 py-1 rounded-md justify-end bg-emerald-300 w-fit text-lg cursor-pointer  text-black ">
						<AiOutlineClose />
					</p>
				</p>
				<Toaster />
				<motion.div
					initial={{ y: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					className="flex flex-col px-6 items-center justify-center">
					<p className="flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-4 ">
						Envoyez nous votre demande
					</p>


					<div className="flex-1 items-center">
						<form className="" onSubmit={handleSubmit}>
							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Nom"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Prénoms"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="email"
										placeholder="Email"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Nom de votre Entreprise"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={company}
										onChange={(e) => setCompany(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Sujet de votre message"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={subject}
										onChange={(e) => setSubject(e.target.value)}
									/>
								</p>
							</div>

							<textarea
								name=""
								id=""
								cols="30"
								rows="5"
								className="w-full m-2  border-b-slate-800 border-2 no-select bg-white text-black p-2 outline-none rounded-md"
								placeholder="Votre message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}></textarea>
							<button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white">
								Send Request
							</button>
						</form>
					</div>
				</motion.div>
			</Modal>
			
            
		</>
	);
}

export default AchivementFr;
