import React from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";
import { selectArticle, selectOpenArticle } from "../../features/articleSlice";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "../../components/Modal";

function Train({
	type,
	theme,
	animateur,
	droitInsc,
	lieu,
	dateLimite,
	date,
	refNumb,
}) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const selectedArticle = useSelector(selectOpenArticle);
	const dispatch = useDispatch();

	const reduxTrain = () => {
		dispatch(
			selectArticle({
				type,
				theme,
				animateur,
				droitInsc,
				lieu,
				dateLimite,
				date,
				refNumb,
			})
		);

		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setEmail("");
		setFirstName("");
		setMessage("");
		setCompany("");
		setName("");
		setSubject("");
	};

	const [name, setName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");
	const [type2, setType2] = useState("");
	const [reftype, setReftype] = useState("");
	const [venue, setVenue] = useState("");
	const [topic, setTopic] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		//keys and services
		const templateId = "template_k06u98r";
		const publicKey = "vRKpOxPaAllPvKD00";
		const serviceId = "service_1icj9w8";

		//create new object

		const templateParams = {
			to_name: "Africa55Durable Message",
			to_ref: reftype,
			to_type2: type2,
			to_venue: venue,
			to_topic: topic,
			from_name: name,
			from_firstName: firstName,
			from_email: email,
			from_company: company,
			subject: subject,
			message: message,
		};

		//send theEmail
		emailjs
			.send(serviceId, templateId, templateParams, publicKey)
			.then((response) => {
				console.log("Message successfully sent", response);
				setEmail("");
				setFirstName("");
				setMessage("");
				setCompany("");
				setName("");
				setSubject("");
				setType2("");
				setReftype("");
				setVenue("");
				setTopic("");
			})
			.catch((error) => {
				console.error("Error sending", error);
			});

		//////////
		const refreshToast = toast.loading(
			"Your message has been sent. We will shortly contact you"
		);
		toast.success("Your message has been sent. We will shortly contact you", {
			id: refreshToast,
			duration: 7000,
		});

		/// navigate to
	};

	return (
		<>
			<motion.div
				onClick={reduxTrain}
				initial={{ y: -100, opacity: 0 }}
				transition={{ duration: 1.2 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				className="flex flex-col bg-green-400/40 items-center justify-center self-center  rounded-md  shadow-md hover:shadow-sky-300 transition-all hover:scale-105 duration-300 ease-in-out min-w-[350px] mt-6 border border-sky-300 hover:border-0 flex-shrink-0 overflow-hidden">
				<div className=" flex text-nowrap w-full  text-white font-semibold text-lg justify-center bg-emerald-500 py-1 flex-shrink-0 ">
					{type}
				</div>
				<div className="bg-white text-lg font-thin text-slate-950 p-3 flex-shrink-0 min-h-[200px] overflow-hidden">
					<div className="flex justify-between">
						<p></p>
						<p className="flex space-x-3">
							<p className="font-bold">ref :</p>
							<p className=" text-green-400 font-light">{refNumb}</p>
						</p>
					</div>
					<ul
						className="flex flex-1 flex-col w-full 
					flex-shrink-0 min-h-[300px] overflow-hidden">
						<li className="flex flex-1 flex-col">
							<ul className="flex space-x-3">
								<li className="font-semibold italic">Date:</li>
								<li>{date}</li>
							</ul>
						</li>
						<li className="flex flex-1 flex-col">
							<ul className="flex space-x-3">
								<li className="font-semibold italic">Topic:</li>
								<li>{theme}</li>
							</ul>
						</li>
						<li className="flex flex-1 flex-col">
							<ul className="flex space-x-3">
								<li className="font-semibold italic">Speaker:</li>
								<li>{animateur}</li>
							</ul>
						</li>
						<li className="flex flex-1 flex-col">
							<ul className="flex space-x-3">
								<li className="font-semibold italic">Venue:</li>
								<li>{lieu}</li>
							</ul>
						</li>
						<li className="flex flex-1 flex-col">
							<ul className="flex space-x-3">
								<li className="font-semibold italic">Fees :</li>
								<li>{droitInsc}</li>
							</ul>
						</li>
						<li className="flex flex-1 flex-col">
							<ul className="flex space-x-3">
								<li className="font-semibold italic">Deadline:</li>
								<li>{dateLimite}</li>
							</ul>
						</li>
					</ul>
				</div>
				<div className="my-4 px-8 py-2 cursor-pointer text-slate-950 text-lg bg-white hover:bg-slate-100 rounded-md">
					Participate
				</div>
			</motion.div>

			{/*Modal*/}
			<Modal isOpen={isModalOpen} closeModal={closeModal}>
				<p className="flex flex-1 px-4 justify-between">
					<p></p>
					<p
						onClick={closeModal}
						className=" flex px-2 py-1 rounded-md justify-end bg-emerald-300 w-fit text-lg cursor-pointer  text-black ">
						<AiOutlineClose />
					</p>
				</p>

				<motion.div
					initial={{ y: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					className="flex flex-col px-6 items-center ">
					<Toaster />

					<div className="flex-1 items-center">
						<form className=""  onSubmit={handleSubmit}>
							<p className=" items-center space-x-3 text-black hidden">
								<p>Type:</p>

								<input
									type="text"
									placeholder="First name"
									className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
									value={type2}
									onChange={(e) => setType2(selectedArticle?.type)}
								/>
							</p>
							<div className=" hidden  flex-col bg-green-100 px-3 py-1 rounded-md drop-shadow-md my-1">
								<p className="flex items-center space-x-3 text-black">
									<p>Ref:</p>
									<input
										type="text"
										placeholder="First name"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={reftype}
										onChange={(e) => setReftype(selectedArticle?.refNumb)}
									/>
								</p>
								<p className="flex items-center space-x-3 text-black">
									<p>Venue:</p>
									<input
										type="text"
										placeholder={selectedArticle?.lieu}
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={venue}
										onChange={(e) => setReftype(selectedArticle?.lieu)}
									/>
								</p>
								<p className="flex items-center space-x-3 text-black">
									<p>Topic:</p>
									<input
										type="text"
										placeholder={selectedArticle?.theme}
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={topic}
										onChange={(e) => setTopic(selectedArticle?.theme)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="First name"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Last Name"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="email"
										placeholder="Email"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Company Name"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={company}
										onChange={(e) => setCompany(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Subject of your message"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={subject}
										onChange={(e) => setSubject(e.target.value)}
									/>
								</p>
							</div>

							<textarea
								name=""
								id=""
								cols="30"
								rows="4"
								className="w-full m-2  border-b-slate-800 border-2 no-select bg-white text-black p-2 outline-none rounded-md"
								placeholder="Your message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}></textarea>
							<button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white">
								Send Message
							</button>
						</form>
					</div>
				</motion.div>
			</Modal>
		</>
	);
}

export default Train;
