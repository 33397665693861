import React from 'react'
import top from '../img/top-pic.jpg'

function Top() {    
  return (
    <div className='flex flex-1'>
        <img src={top} alt="" className='mt-16 sm:mt-0'/>
    </div>
  )
}

export default Top