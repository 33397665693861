// rootReducer.js
import { combineReducers } from 'redux';
import articleReducer from '../features/articleSlice';
// Import other reducers as needed

const rootReducer = combineReducers({
  
  article: articleReducer,
  // Add other reducers here as needed
});

export default rootReducer;
