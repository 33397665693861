import React from 'react'
import Layout from '../../components/Layout'
import Achivement from './Achivement'
import Top from '../../components/Top'


function Achivements() {
  return (
    <Layout>
        <Top />
        <div className='flex flex-col'>
        <Achivement />
        </div>
    </Layout>
  )
}

export default Achivements