import React from "react";
import image from "../../img/child.png";
import image2 from "../../img/img11.jpg";
import { motion } from "framer-motion";

function IntroAboutFr() {
	return (
		<>
			<div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 items-center  ">
					<div className=" bg-emerald-800 p-6 sm:max-w-40 sm:h-[400px] ">
						<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
					</div>
					<img
						src={image}
						alt=""
						className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
					/>
				</motion.div>
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						A propos de nous
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-cyan-600">
					Notre Champion Ousmane DIAKITE "l'Africanophile"
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-black">
						On l'appelle " <b>Mr. Africa</b>".
					</p>
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
					Il ne perd pas de temps pour faire comprendre sa passion pour le développement de l'Afrique. Ousmane DIAKITE a des études avancées en gestion des politiques économiques. Il se fera très vite un profil d'expert en programmation de politique économique.<br/>
Surtout, Ousmane DIAKITE, Monsieur Afrique, est un mandataire des propriétaires de fonds. Elle est accréditée par certaines des 50 plus grandes institutions bancaires et financières mondiales. Et il justifie plusieurs financements de projets à travers le monde.<br/>
Ousmane DIAKITE ou « El professeur » dispense des cours de financement alternatif et de développement. Il fournit des consultations et des recommandations en matière de politique économique aux entités gouvernementales et non gouvernementales.
					</p>
				</motion.div>
			</div>
			{/*--mobile screen--*/}

			<div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
				<motion.div
					initial={{ y: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, y: 0 }}
					className="flex flex-1 flex-col text-black mt-4">
					<p className=" border-b-8 border-yellow-900 pb-2 text-2xl w-fit text-orange-500">
						A propos de nous
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-cyan-600">
					Notre Champion Ousmane DIAKITE "l'Africanophile"
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-black space-x-4">
						We call him " <b>Mr. Africa</b>".
					</p>
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
					Il ne perd pas de temps pour faire comprendre sa passion pour le développement de l'Afrique. Ousmane DIAKITE a des études avancées en gestion des politiques économiques. Il se fera très vite un profil d'expert en programmation de politique économique.<br/>
Surtout, Ousmane DIAKITE, Monsieur Afrique, est un mandataire des propriétaires de fonds. Elle est accréditée par certaines des 50 plus grandes institutions bancaires et financières mondiales. Et il justifie plusieurs financements de projets à travers le monde.<br/>
Ousmane DIAKITE ou « El professeur » dispense des cours de financement alternatif et de développement. Il fournit des consultations et des recommandations en matière de politique économique aux entités gouvernementales et non gouvernementales.
					</p>
				</motion.div>
			</div>

			<motion.div
				initial={{ y: 200, opacity: 0 }}
				transition={{ duration: 1 }}
				whileInView={{ opacity: 1, y: 0 }}
				className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
				<div className=" flex flex-col bg-yellow-900  w-[400px] h-[260px] justify-center "></div>
				<img
					src={image}
					alt=""
					className="w-[380px] h-[220px] object-cover  absolute "
				/>
			</motion.div>

			{/*--Organization--*/}

			<div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black">
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
					Il bénéficie de plusieurs attestations de satisfaction pour l'enseignement et l'encadrement dispensés. Il a à son actif plusieurs certificats de bonne réputation et de bonne moralité dans la gestion d'organisations internationales non gouvernementales que de nombreux vices guettent et menacent constamment. Ousmane DIAKITE est un expert en écodiplomatie.<br/>
Il s'agissait d'un cadre d'ambassade très haut placé et hautement qualifié, réputé dans ses fonctions de chef du service économique et de promotion du commerce bilatéral. Ousmane DIAKITE est discret. De nombreux experts et dirigeants du monde entier marchent dans son ombre.<br/>
Ousmane DIAKITE a occupé et servi le back office des bureaux de conseiller spécial du premier ministre et du cabinet de la présidence de la République. Ousmane DIAKITÉ reste reconnaissant envers tous ses professeurs et formateurs.
					</p>
				</motion.div>

				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 items-center  ">
					<div className=" bg-yellow-900 p-6 sm:max-w-40 sm:h-[400px] ">
						<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
					</div>
					<img
						src={image}
						alt=""
						className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
					/>
				</motion.div>
			</div>
			{/*--mobile screen--*/}

			<div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
				<motion.div
					initial={{ y: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, y: 0 }}
					className="flex flex-1 flex-col text-black mt-4">
					
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
					Il bénéficie de plusieurs attestations de satisfaction pour l'enseignement et l'encadrement dispensés. Il a à son actif plusieurs certificats de bonne réputation et de bonne moralité dans la gestion d'organisations internationales non gouvernementales que de nombreux vices guettent et menacent constamment. Ousmane DIAKITE est un expert en écodiplomatie.<br/>
Il s'agissait d'un cadre d'ambassade très haut placé et hautement qualifié, réputé dans ses fonctions de chef du service économique et de promotion du commerce bilatéral. Ousmane DIAKITE est discret. De nombreux experts et dirigeants du monde entier marchent dans son ombre.<br/>
Ousmane DIAKITE a occupé et servi le back office des bureaux de conseiller spécial du premier ministre et du cabinet de la présidence de la République. Ousmane DIAKITÉ reste reconnaissant envers tous ses professeurs et formateurs.
					</p>
				</motion.div>
			</div>

			<motion.div
				initial={{ y: 200, opacity: 0 }}
				transition={{ duration: 1 }}
				whileInView={{ opacity: 1, y: 0 }}
				className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
				<div className=" flex flex-col bg-yellow-900  w-[400px] h-[260px] justify-center "></div>
				<img
					src={image2}
					alt=""
					className="w-[380px] h-[220px] object-cover  absolute "
				/>
			</motion.div>

			
		</>
	);
}

export default IntroAboutFr;
