import React from 'react'
import HeaderFr from './HeaderFr'
import FooterFr from './FooterFr'




function LayoutFr(props) {

    const { children } = props

  return (
    <> 
      <HeaderFr />
        <main className='flex flex-col bg-white'>
            {children}
        </main>
      <FooterFr />
    </>
  )
}

export default LayoutFr