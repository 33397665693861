import React from "react";
import image from "../../img/img16.jpg";
import { motion } from "framer-motion";

function MywhyTop() {
	return (
		<>
			<div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
					Addressing Africa's Energy Challenge
					</p>
					<p className="text-lg mt-2 font-extralight ml-4 text-justify">
						<b className="font-bold">Africa55durable</b>, in partnership with
						the <b className="font-bold">Neutrino Energy Group</b> is working to change this reality. This strategic partnership aims
						to harness cutting-edge technology to provide innovative and
						sustainable energy solutions across the continent. <br />
						The Neutrino PowerCube, a revolutionary technology developed by{" "}
						Neutrino Energy Group,
						stands at the forefront of this collaboration. It is a technology
						capable of generating energy from neutrinos—omnipresent particles
						that constantly pass through the Earth. The goal is to meet Africa's
						energy needs with renewable, decentralized sources while reducing
						dependence on fossil fuels. With the support of Neutrino Energy
						Group, Africa55Durable is actively involved in initiatives aimed at
						electrifying remote regions where conventional power grids are
						inefficient or non-existent.
						<br />
						As a key partner, Africa55Durable plays a crucial role in the
						implementation of PowerCubes, a technological solution that could
						solve many of Africa's energy access challenges, particularly in its
						most remote regions.
					</p>
				</motion.div>
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 items-center  ">
					<div className=" bg-emerald-800 p-6 sm:max-w-40 sm:h-[400px] ">
						<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
					</div>
					<img
						src={image}
						alt=""
						className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
					/>
				</motion.div>
			</div>
			{/*--mobile screen--*/}

			<div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
				<motion.div
					initial={{ y: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, y: 0 }}
					className="flex flex-1 flex-col text-black mt-4">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						Past successfully records
					</p>
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
						Africa55Durable Program designer and promoter’s master of
						alternative financing solutions for exponential sustainable
						development is well proven by the quality of VVIP in classic banking
						sector he has been training so far. Africa55Durable Program designer
						and promoter is Special financial advisor to funds owners.
						<br />
						Africa55Durable Program designer promoter has been doing a lot for
						so many entities in terms of financing and supporting through SBLC.
						Africa55Durable Program designer and promoter proved all the
						statements before and can share details of evidences only physically
						in one on one meeting.
					</p>
				</motion.div>
			</div>

			<motion.div
				initial={{ y: 200, opacity: 0 }}
				transition={{ duration: 1 }}
				whileInView={{ opacity: 1, y: 0 }}
				className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
				<div className=" flex flex-col bg-emerald-800  w-[400px] h-[260px] justify-center "></div>
				<img
					src={image}
					alt=""
					className="w-[380px] h-[220px] object-cover  absolute "
				/>
			</motion.div>
		</>
	);
}

export default MywhyTop;
