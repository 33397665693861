import React from "react";
import IntroAbout from "./IntroAbout";
import Layout from "../../components/Layout";
import Top from "../../components/Top";

function About() {
	return (
		<Layout>
			<Top />
			<IntroAbout />
		</Layout>
	);
}

export default About;
