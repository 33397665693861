const sliderData = [
  {
    image: "image/car8.png",
    image1: "image/scar8.png",
    heading: "Slide One",
    title:"Addressing Africa's Energy Challenge",
    topic:"with NEUTRINO ENERGY GROUP",
    seeLink:"",
    subLink:"",
    desc: "Apartnership to meet Africa's growing energy needs",
  },

  {
    image: "image/car1.png",
    image1: "image/scar1.png",
    heading: "Slide One",
    title:"Securing Africa",
    topic:"With Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Securing the 55 States of Africa borders",
  },

  {
    image: "image/car2.png",
    image1: "image/scar2.png",
    heading: "Slide One",
    title:"Fighting corruption",
    topic:"With Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Fighting against corruption and bribery",
  },

  {
    image: "image/car4.png",
    image1: "image/scar4.png",
    heading: "Slide One",
    title:"Build Smart Africa",
    topic:"With Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Construction of 55 new smart cities and 55 smart villages across Africa",
  },

  {
    image: "image/car3.png",
    image1: "image/scar3.png",
    heading: "Slide One",
    title:"Education",
    topic:"with Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Education Health Reforestation",
  },
  
  {
    image: "image/car5.png",
    image1: "image/scar5.png",
    heading: "Slide One",
    title:"Industrializing Africa",
    topic:"with Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Industrialization of Africa and green energy",
  },

  {
    image: "image/car6.png",
    image1: "image/scar6.png",
    heading: "Slide One",
    title:"FinTech and NFT",
    topic:"with Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "FinTech Blockchain Banking NFT cryptographic SBLC tokenization of natural ressources and asset",
  },

  {
    image: "image/car7.png",
    image1: "image/scar7.png",
    heading: "Slide One",
    title:"Debt redemption",
    topic:"with Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Debt redemption for Africa",
  },
];


const etechData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];


const pieceData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },
  

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];

const accessoireData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];

export {sliderData, etechData, pieceData, accessoireData}