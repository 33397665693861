import React, { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { selectArticle, selectOpenArticle } from "../../features/articleSlice";
import Modal from "../../components/Modal";
import { AiOutlineClose } from "react-icons/ai";

function OpportunityFr({ title, des, image }) {
	const [isModalOpen, setIsModalOpen] = useState(false);
	const selectedArticle = useSelector(selectOpenArticle);
	const dispatch = useDispatch();

	const reduxOpprt = () => {
		dispatch(
			selectArticle({
				title,
				des,
				image,
			})
		);

		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setEmail("");
		setFirstName("");
		setMessage("");
		setCompany("");
		setName("");
		setSubject("");
	};

	const [name, setName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	return (
		<>
			<motion.div
      onClick={reduxOpprt}
				initial={{ y: -100, opacity: 0 }}
				transition={{ duration: 1.2 }}
				whileInView={{ opacity: 1, y: 0 }}
				viewport={{ once: true }}
				className="flex flex-col justify-between bg-slate-400/40 items-center  self-center  rounded-md  shadow-md hover:shadow-sky-300 transition-all hover:scale-105 duration-300 ease-in-out max-w-[400px] mt-6 border border-sky-300 hover:border-0 flex-shrink-0 max-h-[750px]">
				<div className=" min-h-[200px] flex flex-1 justify-center bg-transparent items-center">
					<img
						src={image}
						alt=""
						className=" object-cover w-40 h-36 shrink-0 "
					/>
				</div>

				<div className=" flex flex-1 w-full max-h-10 justify-center text-white font-light text-xl bg-emerald-800 items-center  ">
					{title}
				</div>

				<div className="bg-white text-lg  font-extralight text-black p-3 min-h-[280px] flex flex-col justify-between">
					<p className=" text-clip">{des}</p>
					<p className="flex justify-center bg-green-400 py-1 hover:bg-green-300  cursor-pointer rounded-md">
						Contacter Nous
					</p>
				</div>
			</motion.div>

      <Modal isOpen={isModalOpen} closeModal={closeModal}>
				<p className="flex flex-1 px-4 justify-between">
					<p></p>
					<p onClick={closeModal} className=" flex px-2 py-1 rounded-md justify-end bg-emerald-300 w-fit text-lg cursor-pointer  text-black ">
						<AiOutlineClose />
					</p>
				</p>

				<motion.div
					initial={{ y: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					className="flex flex-col px-6 items-center ">
					

					<div className="flex-1 items-center">
						<form className="">
						<p className="flex justify-center text-lg  sm:text-lg py-4 text-[#c2742b] font-semibold  ">
						{selectedArticle?.title}
					</p>
					<div className="flex  flex-col bg-green-100 px-3 py-2 rounded-md drop-shadow-md my-1">
					<p className="flex items-center space-x-3 text-black">
						
					    <p className="text-xs">{selectedArticle?.des}</p> 
					</p>
					
					</div>
					
							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Prénoms"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Nom"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="email"
										placeholder="Email"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Nom d'entreprise"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={company}
										onChange={(e) => setCompany(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Sujet de votre message"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={subject}
										onChange={(e) => setSubject(e.target.value)}
									/>
								</p>
							</div>

							<textarea
								name=""
								id=""
								cols="30"
								rows="4"
								className="w-full m-2  border-b-slate-800 border-2 no-select bg-white text-black p-2 outline-none rounded-md"
								placeholder="Votre message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}></textarea>
							<button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white">
								Envoyer le Message
							</button>
						</form>
					</div>
				</motion.div>
			</Modal>
		</>
	);
}

export default OpportunityFr;
