import React, { useState, useEffect } from "react";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import emailjs from '@emailjs/browser'
import { Toaster, toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { LuChevronDown } from "react-icons/lu";
import { AiOutlineMail } from "react-icons/ai";
import Modal from "./Modal";

export default function HeaderFr() {
	const [nav, setNav] = useState(false);
	const [color, setColor] = useState("transparent");
	const [textColor, setTextColor] = useState("#75542f");
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleNav = () => {
		setNav(!nav);
	};

	useEffect(() => {
		const changeColor = () => {
			if (window.scrollY >= 90) {
				setColor("#ffffff");
				setTextColor("#000000 ");
			} else {
				setColor("transparent");
				setTextColor("#FFAB4C");
			}
		};
		window.addEventListener("scroll", changeColor);
	}, []);

	const openModal = () => {
		setIsModalOpen(true);
	};

	const closeModal = () => {
		setIsModalOpen(false);
		setEmail("");
		setFirstName("");
		setMessage("");
		setCompany("");
		setName("");
		setSubject("");
	};

	const [name, setName] = useState("");
	const [firstName, setFirstName] = useState("");
	const [email, setEmail] = useState("");
	const [company, setCompany] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault()
		//keys and services
		const templateId ='template_k06u98r'
		const publicKey = 'vRKpOxPaAllPvKD00'
		const serviceId = 'service_1icj9w8' 
	
		//create new object
	
		const templateParams ={
		  to_name:'Africa55Durable Message',
		  from_name:name,
		  from_firstName:firstName,
		  from_email:email,
		  from_company:company,
		  subject:subject,
		  message:message,
		}
		
		//send theEmail
		emailjs.send(serviceId, templateId, templateParams, publicKey)
		.then((response)=>{
		  console.log('Email envoyé avec succès', response)
		  setEmail('')
		  setFirstName('')
		  setMessage('')
		  setCompany('')
		  setName('')
		  setSubject('')
		})
		.catch((error)=>{
		  console.error('Error sending', error)
		})
	
		//////////
		const refreshToast = toast.loading("Votre message a été envoyé! notre équipe vous contactera.");
				toast.success("Votre message a été envoyé! notre équipe vous contactera", {
					id: refreshToast,
			duration: 7000,
				});
	
		  /// navigate to
	  }
  
  
  
  
  

	return (
		<>
			<div
				style={{ backgroundColor: `${color}` }}
				className="fixed left-0 top-0 w-screen  z-[10] ease-in duration-300  text-white font-[300] px-6 py-2 items-center  hidden sm:flex flex-col justify-between">
				<div className=" text-blue-800 justify-end w-full sm:flex items-center  font-extralight text-lg px-6 z-20 italic hidden space-x-6 mt-2">
					<p className="flex space-x-4">
						<Link to="/welcome">
							<img
								src="/eng.png"
								alt="English"
								className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
							/>
						</Link>
						
					</p>
					<p
						onClick={openModal}
						className="flex space-x-4 items-center cursor-pointer">
						<AiOutlineMail className="mr-1" />{" "}
						secretary.general@africa55durable.com
					</p>
				</div>
				<div className="flex -mt-6">
					<div
						style={{ backgroundColor: `${color}` }}
						className="flex justify-between items-center w-full ease-in duration-300 ">
						<div
							style={{ color: `${textColor}` }}
							className="hidden sm:flex items-center justify-between space-x-10">
							<div className=" cursor-pointer hover:bg-green-50 rounded-md py-2 px-4 duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a]">
								<Link to="/welcome">Accueil</Link>
							</div>
							<div className=" cursor-pointer hover:bg-green-50 rounded-md py-2 px-4 duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a]">
								<Link to="/apropos">A Propos</Link>
							</div>
							<div className="dropdown dropdown-hover">
								<div
									tabIndex={0}
									className="  py-2 px-4 rounded-md duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a] flex items-center gap-x-1 hover:bg-green-50">
									<p>A Savoir</p> <LuChevronDown />
								</div>
								<ul
									tabIndex={0}
									className="dropdown-content menu bg-slate-50/15  backdrop-blur-md rounded-box z-[1] w-52 p-2 shadow">
									<li className="cursor-pointer py-2 px-3 rounded-md duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a] hover:bg-green-50">
										<Link to="/newsfr">News</Link>
									</li>

									<li className="cursor-pointer py-2 px-3 rounded-md duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a] hover:bg-green-50">
										<Link to="/trainingfr">Séminaires</Link>
									</li>
									<li className="cursor-pointer py-2 px-3 rounded-md duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a] hover:bg-green-50">
										<Link to="/achivementfr">Réalisations</Link>
									</li>
									<li className="cursor-pointer py-2 px-3 rounded-md duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a] hover:bg-green-50">
										<Link to="/honorfr">Hommages</Link>
									</li>
								</ul>
							</div>
						</div>

						<Link to="/">
							<img
								src="/logo.png"
								alt=""
								className="hidden sm:inline-block h-[114px] w-[120px] object-fill rounded-md"
							/>

							<img
								src="/logo.png"
								alt=""
								className="sm:hidden inline-block h-[95px] w-[100px] object-fill rounded-md"
							/>
						</Link>

						<div
							style={{ color: `${textColor}` }}
							className="hidden sm:flex items-center justify-between space-x-10 ">
							<div className=" cursor-pointer hover:bg-green-50 rounded-md py-2 px-6 duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a]">
								<Link to="/partnersfr">Partenaires & FTF</Link>
							</div>
							<div className=" cursor-pointer hover:bg-green-50 rounded-md py-2 px-4 duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a]">
								<Link to="/memberfr">Membre</Link>
							</div>
							<div className=" cursor-pointer hover:bg-green-50 rounded-md py-2 px-4 duration-300 relative font-[300] text-xl  hover:text-[#bf6b0a]">
								<Link to="/opportunitefr">Opportunités</Link>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/*mobile vue*/}

			<div
				style={{ backgroundColor: `${color}` }}
				className=" fixed left-0 top-0 w-screen  z-[10] ease-in duration-300  text-white font-[300] px-6 py-2 items-center  flex sm:hidden  justify-between pt-8 ">
				<div className="flex -mt-6">
					<div
						style={{ backgroundColor: `${color}` }}
						className="flex justify-between items-center w-full ease-in duration-300 ">
						
						<div className="flex items-center space-x-4">
								<img
									src="/logo.png"
									alt=""
									className="sm:hidden inline-block h-[95px] w-[100px] object-fill rounded-md"
								/>
								<p className="flex flex-col items-center space-y-4">
									<Link to="/welcome">
									<img
										src="/eng.png"
										alt="English"
										className="h-3 w-5 object-cover rounded-sm cursor-pointer transition-all overflow-hidden hover:scale-105 duration-200 ease-in-out shadow-md shadow-green-300"
									/>
									</Link>	
									
								</p>
							</div>
					</div>
				</div>
				<div className="flex ">
					{/*Mobile button*/}
					<div onClick={handleNav} className="block sm:hidden z-10 ">
						{nav ? (
							<AiOutlineClose size={20} style={{ color: `${textColor}` }} />
						) : (
							<AiOutlineMenu size={20} style={{ color: `${textColor}` }} />
						)}
					</div>
					<div
						className={
							nav
								? "sm:hidden absolute top-0 left-0 right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
								: "sm:hidden absolute top-0 left-[-100%] right-0 bottom-0 flex justify-center items-center w-full h-screen bg-black text-center ease-in duration-300"
						}>
						<ul className="flex flex-col items-center justify-center space-y-2">
							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/welcome" onClick={handleNav}>
								Accueil
								</Link>
							</li>
							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/apropos" onClick={handleNav}>
									A Propos de Nous
								</Link>
							</li>

							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/newsFr" onClick={handleNav}>
									News
								</Link>
							</li>

							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/trainingfr" onClick={handleNav}>
									Séminaires
								</Link>
							</li>

							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/achivementfr" onClick={handleNav}>
									Réalisations
								</Link>
							</li>

							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/honorfr" onClick={handleNav}>
									Hommages
								</Link>
							</li>
							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/partnersfr" onClick={handleNav}>
									Partenaires & FTF
								</Link>
							</li>
							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/memberfr" onClick={handleNav}>
									Devenir Membre
								</Link>
							</li>
							<li className=" py-1 duration-300 relative font-[300] text-xl after:absolute after:bottom-0 after:right-full after:bg-white after:z-10 after:w-full after:h-[2px] overflow-hidden hover:after:translate-x-full after:duration-300 hover:text-white">
								<Link to="/opportunitefr" onClick={handleNav}>
									Opportunités
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</div>

			{/*Modal*/}
			<Modal isOpen={isModalOpen} closeModal={closeModal}>
				<p className="flex flex-1 px-4 justify-between">
					<p></p>
					<p
						onClick={closeModal}
						className=" flex px-2 py-1 rounded-md justify-end bg-emerald-300 w-fit text-lg cursor-pointer  text-black ">
						<AiOutlineClose />
					</p>
				</p>
				<motion.div
					initial={{ y: -100, opacity: 0 }}
					transition={{ duration: 1.2 }}
					whileInView={{ opacity: 1, y: 0 }}
					viewport={{ once: true }}
					className="flex flex-col px-6 items-center justify-center">
					<p className="flex justify-center text-lg  sm:text-3xl py-4 text-[#c2742b] font-semibold mt-11 ">
						Contactez-nous
					</p>
					<Toaster />
					<div className="flex-1 items-center">
						<form className="" onSubmit={handleSubmit}>
							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Prénoms"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={firstName}
										onChange={(e) => setFirstName(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Nom de famille"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={name}
										onChange={(e) => setName(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="email"
										placeholder="Email"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</p>

								<p className="flex-1">
									<input
										type="text"
										placeholder="Nom de votre entreprise"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={company}
										onChange={(e) => setCompany(e.target.value)}
									/>
								</p>
							</div>

							<div className="flex space-x-2">
								<p className="flex-1">
									<input
										type="text"
										placeholder="Sujet de votre message"
										className="w-full m-2  border-b-slate-800 border-2 no-select p-2 outline-none bg-white text-black rounded-md"
										value={subject}
										onChange={(e) => setSubject(e.target.value)}
									/>
								</p>
							</div>

							<textarea
								name=""
								id=""
								cols="30"
								rows="10"
								className="w-full m-2  border-b-slate-800 border-2 no-select bg-white text-black p-2 outline-none rounded-md"
								placeholder="Votre message"
								value={message}
								onChange={(e) => setMessage(e.target.value)}></textarea>
							<button className="w-full m-2 p-2 rounded-md bg-cyan-500 hover:bg-cyan-600 text-2xl text-white">
								Envoyer votre Message
							</button>
						</form>
					</div>
				</motion.div>
			</Modal>
		</>
	);
}
