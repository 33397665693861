import React, { useState } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

function Welcome() {
	const [eng, setEng] = useState(true);
	const [fren, setFren] = useState(false);

	const engAct = () => {
		setFren(false);
		setEng(true);
	};

	const frenAct = () => {
		setFren(true);
		setEng(false);
	};

	return (
		<>
			{eng && (
				<div className="relative bg-slate-50 min-h-screen text-slate-950 justify-between items-center flex flex-col z-20 ">
					<div className="flex flex-col justify-center">
						<div className="flex items-center justify-between px-4">
							<div className="flex items-center space-x-5 mt-3">
								<p
									onClick={frenAct}
									className="flex flex-col items-center space-y-1">
									<img
										src="/franc.png"
										alt="Français"
										className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Fr</p>
								</p>
								<p onClick={engAct} className="flex flex-col items-center space-y-1">									
									<img
										src="/eng.png"
										alt="English"
										className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Eng</p>
								</p>
							</div>
							<div className="flex items-center space-x-5 mt-3">
								<a
									href="https://neutrino-energy.com/"
									target="_blank"
									rel="noreferrer"
									className="flex flex-col items-center space-y-1">
									<img
										src="/neutrino.png"
										alt="Français"
										className="h-10 w-10 object-fill rounded-full cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Neutrino Energy Group</p>
								</a>

								<a
									href="https://www.camerongroupinternational.co.uk/"
									target="_blank"
									rel="noreferrer"
									className="flex flex-col items-center space-y-1">
									<img
										src="/lgo.jpg"
										alt="Français"
										className="h-10 w-10 object-fill rounded-full cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Cameron Group International</p>
								</a>
								
							</div>
						</div>

						<div className="flex w-full sm:my-6 mt-6">
							<img
								src="/logo.png"
								alt=""
								className="inline-block h-[114px] w-[120px] object-fill rounded-md mx-auto"
							/>
						</div>

						<div className="flex w-full my-2">
							<motion.img
								initial={{ opacity: 0, y: 100 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{
									duration: 1,
								}}
								src="/cube.png"
								alt="power-cube"
								className="absolute bottom-5 right-0  z-0 sm:w-72 w-20 hidden sm:inline-block"
							/>
						</div>
						<div className="flex w-full justify-center text-xl sm:text-4xl font-light sm:my-6 mt-2 z-20">
							Welcome to AFRICA55DURABLE
						</div>
						<div className="flex  w-full justify-center mb-4 text-sm sm:text-3xl font-thin text-center z-20 px-3">
							Knock on our door we will open to you, Ask, we will serve you majestically AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center mb-4 text-ms text-center px-3 sm:text-2xl z-20 font-thin">
							The Circle of best entities of exponential growth and development in the world
						</div>
						<div className="flex w-full justify-center mb-4 text-sm text-center px-4 sm:text-xl z-20 font-thin">
							AFRICA55DURABLE : EXPONENTIAL GROWTH & DEVELOPMENT
						</div>
						<div className="flex w-full justify-center mb-4 text-lg text-center px-3 sm:text-2xl z-20 font-thin">
							Contact: secretary.general@africa55durable.com
						</div>
						<div className="flex w-full justify-center my-4">
							<div className="flex w-full justify-center my-1 sm:my-6">
								<Link to="/bienvenue">
									<div className="text-xl bg-teal-800 drop-shadow-md shadow-md shadow-green-300  text-white hover:bg-teal-600 z-20 px-4 py-2 rounded-full">
										{" "}
										Enter here for more details
									</div>
								</Link>
							</div>
						</div>
					</div>
					<div className="flex justify-center w-full text-xl py-2 font-thin bg-green-100">
						<motion.img
							initial={{ opacity: 0, y: 100 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{
								duration: 1,
							}}
							src="/cube.png"
							alt="power-cube"
							className="absolute bottom-5 left-0  z-0 sm:w-72 w-40  sm:hidden ml-5 rounded-full"
							/>
							© 2024 AFRICA55DURABLE. All rights reserved
					</div>
				</div>
			)}

			{fren && (
				<div className="relative bg-slate-50 min-h-screen text-slate-950 justify-between items-center flex flex-col z-20">
					<div className="flex flex-col justify-center">
					<div className="flex items-center justify-between px-4">
							<div className="flex items-center space-x-5 mt-3">
								<p
									onClick={frenAct}
									className="flex flex-col items-center space-y-1">
									<img
										src="/franc.png"
										alt="Français"
										className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Fr</p>
								</p>
								<p onClick={engAct} className="flex flex-col items-center space-y-1">									
									<img
										src="/eng.png"
										alt="English"
										className="h-5 w-8 object-fill rounded-sm cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Eng</p>
								</p>
							</div>
							<div className="flex items-center space-x-5 mt-3">
								<a
									href="https://neutrino-energy.com/"
									target="_blank"
									rel="noreferrer"
									className="flex flex-col items-center space-y-1">
									<img
										src="/neutrino.png"
										alt="Français"
										className="h-10 w-10 object-fill rounded-full cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Neutrino Energy Group</p>
								</a>

								<a
									href="https://www.camerongroupinternational.co.uk/"
									target="_blank"
									rel="noreferrer"
									className="flex flex-col items-center space-y-1">
									<img
										src="/lgo.jpg"
										alt="Français"
										className="h-10 w-10 object-fill rounded-full cursor-pointer transition-all hover:scale-105 duration-300 ease-in-out shadow-md shadow-green-300"
									/>
									<p className="hidden sm:flex">Cameron Group International</p>
								</a>
								
							</div>
						</div>

						<div className="flex w-full sm:my-6 mt-6">
							<img
								src="/logo.png"
								alt=""
								className="inline-block h-[114px] w-[120px] object-fill rounded-md mx-auto"
							/>
						</div>
						<div className="flex w-full my-2">
							<motion.img
								initial={{ opacity: 0, y: 100 }}
								whileInView={{ opacity: 1, y: 0 }}
								transition={{
									duration: 1,
								}}
								src="/cube.png"
								alt="power-cube"
								className="absolute bottom-5 right-0  z-0 sm:w-72 w-20 hidden sm:inline-block"
							/>
						</div>
						<div className="flex w-full justify-center text-xl sm:text-4xl font-light z-20  sm:my-6 mt-2">
							Bienvenue à AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center text-sm sm:text-2xl font-thin z-20 text-center px-3">
							Frappez à notre porte et nous vous ouvrirons, Demandez et nous
							vous servirons majestueusement AFRICA55DURABLE
						</div>
						<div className="flex w-full justify-center mb-4 text-sm text-center px-3 z-20 sm:text-2xl font-thin">
							Le Cercle des meilleures entités de croissance et de développement
							exponentiel au monde
						</div>
						<div className="flex w-full justify-center mb-2 text-sm text-center px-4 z-20 sm:text-xl font-thin">
							AFRICA55DURABLE : CROISSANCE & DÉVELOPPEMENT EXPONENTIELS
						</div>
						<div className="flex w-full justify-center mb-2 text-lg text-center px-3 z-20 sm:text-2xl font-thin">
							Contact: secretary.general@africa55durable.com
						</div>
						<div className="flex w-full justify-center my-1 sm:my-6">
							<Link to="/bienvenue">
								<div className="text-xl bg-teal-800 drop-shadow-md shadow-md shadow-green-300  text-white hover:bg-teal-600 px-4 py-2 z-20 rounded-full">
									{" "}
									Entrez pour en savoir plus
								</div>
							</Link>
						</div>
					</div>
					<div className="flex justify-center w-full text-xl py-2 font-thin bg-green-100">
						<motion.img
							initial={{ opacity: 0, y: 100 }}
							whileInView={{ opacity: 1, y: 0 }}
							transition={{
								duration: 1,
							}}
							src="/cube.png"
							alt="power-cube"
							className="absolute bottom-5 left-0  z-0 sm:w-72 w-40  sm:hidden ml-5"
							/>
							© 2024 AFRICA55DURABLE. All rights reserved
					</div>
				</div>
			)}
		</>
	);
}

export default Welcome;
