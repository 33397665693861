import React from "react";
import Opportunity from "./Opportunity";
import { OpportunityData } from "../../data/data";

function Opportunities() {
	return (
		<>
		<div className="hidden sm:flex w-full overflow-x-auto  scrollbar-hide space-x-4 my-4">
			{OpportunityData &&
				OpportunityData.map((data, id) => (
					<div key={id}>
						<Opportunity
							title={data.title}
							image={data?.image}
							des={data?.des}
						/>
					</div>
				))}
		</div>
		{/* mobile view*/}

		<div className="flex flex-col sm:hidden w-full px-4  my-4">
			{OpportunityData &&
				OpportunityData.map((data, id) => (
					<div key={id}>
						<Opportunity
							title={data.title}
							image={data?.image}
							des={data?.des}
						/>
					</div>
				))}
		</div>
		
		</>
	);
}

export default Opportunities;
