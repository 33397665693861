import React from "react";
import { GiPositionMarker } from "react-icons/gi";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

function Footer() {
	return (
		<>
			<div className=" hidden space-x-16 items-center sm:flex flex-1 px-32 text-blue-900  pb-4 bg-green-200 mt-6 pt-6">
				<div className="flex w-2/6">
					<Link to="/welcome">
						<motion.img
							initial={{ y: -50, opacity: 0 }}
							transition={{ duration: 1 }}
							whileInView={{ opacity: 1, y: 0 }}
							src="/logo.png"
							alt="Logo Africa55Durable"
							className="inline-block h-[95px] w-[100px] object-fill rounded-md"
						/>
					</Link>
				</div>
				<div className="flex flex-1 flex-col justify-center w-3/6">
					<motion.p
						initial={{ x: 200, opacity: 0 }}
						transition={{ duration: 1 }}
						whileInView={{ opacity: 1, x: 0 }}
						className=" text-xl italic uppercase flex justify-center my-4">
						Africa55Durable
					</motion.p>
					<p className="flex flex-col flex-1 justify-center w-full">
						<ul className="flex w-full items-center space-x-4 ml-2 justify-center ">
							<p className="flex items-center">
								<GiPositionMarker className="mr-2" /> Location:
							</p>
							<p className="flex items-center">
								<li>
									<img src="/can.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Canada</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/usa.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">U.S.A</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/eng.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">U.K</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/ci.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="text-nowrap underline underline-offset-4 ml-1">
									Côte d'Ivoire
								</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/sfa.jpg" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="text-nowrap underline underline-offset-4 ml-1">
									South Africa
								</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/ken.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Kenya</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/alg.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Algeria</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/dub.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Dubai</li>
							</p>
						</ul>
						<ul className="flex items-center space-x-4 ml-2 mt-1 justify-center">
							<p className="flex items-center">
								<li>
									<img src="/chn.jpeg" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">China</li>
							</p>

							<p className="flex items-center">
								<li>
									<img src="/sing.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Singapore</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/mal.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Malaysia</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/thai.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Thailand</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/aust.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">Australia</li>
							</p>
							<p className="flex items-center">
								<li>
									<img src="/seyc.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								<li className="underline underline-offset-4 ml-1">
									Seychelles
								</li>
								
							</p>
							<p className="flex items-center">
								<li>
									<img src="/maurit.png" alt="" className="w-3 h-3 object-fill" />
								</li>
								
								<li className="underline underline-offset-4 ml-1">
								Mauritius 
								</li>
							</p>
						</ul>
					</p>

					<p className=" flex w-full justify-center mt-3 text-sm  items-center">
						{" "}
						<MdOutlineAlternateEmail className="mx-2" /> Email:
						secretary.general@africa55durable.com
					</p>
				</div>
			</div>

			<div className="sm:hidden flex flex-1  items-center text-blue-900 justify-between  pb-4 bg-green-200 mt-6 pt-6 px-4">
				<div className="flex items-center flex-1 justify-between ">
					<div className="flex w-2/5">
						<Link to="/welcome">
							<motion.img
								initial={{ y: -50, opacity: 0 }}
								transition={{ duration: 1 }}
								whileInView={{ opacity: 1, y: 0 }}
								src="/logo.png"
								alt="Logo Africa55Durable"
								className="inline-block shrink-0 h-[95px] w-[100px] object-fill rounded-md"
							/>
						</Link>
					</div>
					<div className="flex flex-col flex-1 w-3/5">
						
						<div className="flex flex-col flex-1 justify-center w-full">
						<motion.p
							initial={{ x: 200, opacity: 0 }}
							transition={{ duration: 1 }}
							whileInView={{ opacity: 1, x: 0 }}
							className=" text-sm italic uppercase flex  w-full justify-center ">
							Africa55Durable
						</motion.p>
							<ul className="flex w-full items-center space-x-2  justify-center text-xs">
								<GiPositionMarker className="mr-2" /> Location:
								<li className="underline underline-offset-4 ">Canada</li>
								<li className="underline underline-offset-4 ">U.S.A</li>
								<li className="underline underline-offset-4 ">U.K</li>
							</ul>
							<ul className="flex w-full items-center space-x-2  justify-center text-xs">
								<li className="text-nowrap underline underline-offset-4">
									Côte d'Ivoire
								</li>

								<li className="text-nowrap underline underline-offset-4 ">
									South Africa
								</li>

								<li className="underline underline-offset-4 ">Kenya</li>

								<li className="underline underline-offset-4 ">Algeria</li>
							</ul>
							<ul className="flex w-full items-center space-x-2  justify-center text-xs">
								<li className="underline underline-offset-4 ">Dubai</li>

								<li className="underline underline-offset-4 ">Singapore</li>

								<li className="underline underline-offset-4 ">Malaysia</li>

								<li className="underline underline-offset-4 ">Thailand</li>
							</ul>
							<ul className="flex items-center ml-2 mt-1 justify-center">
								<li className="underline underline-offset-4 ">China</li>

								
									<li className="underline underline-offset-4 ml-1">
										Australia
									</li>
								
									<li className="underline underline-offset-4 ml-1">
										Seychelles
									</li>
									<li className="underline underline-offset-4 ml-1">
									Mauritius 
									</li>
							
							</ul>
						</div>
						<p className=" flex text-xs  items-center">
							{" "}
							<MdOutlineAlternateEmail className="" />: secretary.general@africa55durable.com
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default Footer;
