import About from "./pages/about/About";
import Accueil from "./pages/accueil/Accueil";
import { Route, Routes } from "react-router-dom";
import Welcome from "./pages/welcome/Welcome";
import Partners from "./pages/partners/Partners";
import Member from "./pages/member/Member";
import News from "./pages/news/News";
import Training from "./pages/Training/Training";
import ViewTraing from "./pages/Training/ViewTraing";
import Achivements from "./pages/achivements/Achivements";
import Honors from "./pages/honor/Honors";
import Opportunite from "./pages/opportunity/Opportunite";
import AccueilFr from "./pages/accueilfr/AccueilFr";
import AboutFr from "./pages/aboutfr/AboutFr";
import NewsFr from "./pages/newsfr/NewsFr";
import ViewPostFr from "./pages/newsfr/ViewPostFr";
import TrainingFr from "./pages/Trainingfr/TrainingFr";
import ViewTraingFr from "./pages/Trainingfr/ViewTraingFr";
import AchivementsFr from "./pages/achivementsfr/AchivementsFr";
import HonorsFr from "./pages/honorfr/HonorsFr";
import PartnersFr from "./pages/partnersfr/PartnersFr";
import MemberFr from "./pages/memberfr/MemberFr";
import ViewPost from "./pages/news/ViewPost";
import OpportuniteFr from "./pages/opportunityfr/OpportuniteFr";

function App() {
	return (
		<Routes>
			<Route
				path="/welcome"
				element={
					<section className="snap-start">
						<Accueil />
					</section>
				}
			/>

			<Route
				path="/bienvenue"
				element={
					<section className="snap-start">
						<AccueilFr />
					</section>
				}
			/>

			<Route
				path="/"
				element={
					<section className="snap-start">
						<Welcome />
					</section>
				}
			/>

			<Route
				path="/partners"
				element={
					<section className="snap-start">
						<Partners />
					</section>
				}
			/>

			<Route
				path="/partnersfr"
				element={
					<section className="snap-start">
						<PartnersFr />
					</section>
				}
			/>

			<Route
				path="/member"
				element={
					<section className="snap-start">
						<Member />
					</section>
				}
			/>

			<Route
				path="/memberfr"
				element={
					<section className="snap-start">
						<MemberFr />
					</section>
				}
			/>

			<Route
				path="/aboutus"
				element={
					<section className="snap-start">
						<About />
					</section>
				}
			/>

			<Route
				path="/apropos"
				element={
					<section className="snap-start">
						<AboutFr />
					</section>
				}
			/>

			<Route
				path="/news"
				element={
					<section className="snap-start">
						<News />
					</section>
				}
			/>

			<Route
				path="/newsfr"
				element={
					<section className="snap-start">
						<NewsFr />
					</section>
				}
			/>

			<Route
				path="/viewpostfr"
				element={
					<section className="snap-start">
						<ViewPostFr />
					</section>
				}
			/>

			<Route
				path="/viewpost"
				element={
					<section className="snap-start">
						<ViewPost />
					</section>
				}
			/>

			<Route
				path="/training"
				element={
					<section className="snap-start">
						<Training />
					</section>
				}
			/>

			<Route
				path="/viewtraining"
				element={
					<section className="snap-start">
						<ViewTraing />
					</section>
				}
			/>

			<Route
				path="/trainingfr"
				element={
					<section className="snap-start">
						<TrainingFr />
					</section>
				}
			/>

			<Route
				path="/viewtrainingfr"
				element={
					<section className="snap-start">
						<ViewTraingFr />
					</section>
				}
			/>

			<Route
				path="/achivement"
				element={
					<section className="snap-start">
						<Achivements />
					</section>
				}
			/>

			<Route
				path="/achivementfr"
				element={
					<section className="snap-start">
						<AchivementsFr />
					</section>
				}
			/>

			<Route
				path="/honor"
				element={
					<section className="snap-start">
						<Honors />
					</section>
				}
			/>

			<Route
				path="/honorfr"
				element={
					<section className="snap-start">
						<HonorsFr />
					</section>
				}
			/>

			<Route
				path="/opportunite"
				element={
					<section className="snap-start">
						<Opportunite />
					</section>
				}
			/>

			<Route
				path="/opportunitefr"
				element={
					<section className="snap-start">
						<OpportuniteFr />
					</section>
				}
			/>
		</Routes>
	);
}

export default App;
