import React from "react";
import Top from "../../components/Top";
import LayoutFr from "../../components/LayoutFr";
import IntroAboutFr from "./IntroAboutFr";

function AboutFr() {
	return (
		<LayoutFr>
			<Top />
			<IntroAboutFr />
		</LayoutFr>
	);
}

export default AboutFr;
