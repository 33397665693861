const NewsData = [
	{
		id: 1,
		title: "Exponential development",
		image: "/geneve.jpeg",
		des: "Sur les fonds hors grand livre : différence entre mythe et réalité. Le 19 août 2024 à Genève et Singapour.",
	},
	{
		id: 2,
		title: "Exponential development",
		image: "/beijing.jpg",
		des: "La cryptographie, moyen de mobilisation d'actifs par les banques. Le 19 août 2024 à Pékin.",
	},
	{
		id: 3,
		title: "Exponential development",
		image: "/hong.jpg",
		des: "Cryptographie : cryptomonnaie et financement alternatif du commerce international. Le 19 août 2024 à Pékin et Hong Kong.",
	},
	{
		id: 4,
		title: "PAPSS for transborder payment",
		image: "/pass.jpg",
		des: "Les partenaires d'AFRICA55DURABLE ont conçu le PAPSS au profit du paiement transfrontalier en Afrique.",
	},
	{
		id: 5,
		title: "Kenya Gold Miners in Beijing",
		image: "/beijing.jpg",
		des: "Kenya Gold Miners confirmed situated in my Beijing Compound.",
	},
	{
		id: 6,
		title: "Maître Daniel Rochefort and Associate",
		image: "/montreal.jpeg",
		des: "Africa55Durable a le plaisir de vous informer que son équipe juridique a été renforcée par les études de Maître Daniel Rochefort et Associés. L'avocat Maître Daniel Rochefort était un associé d'Eliott Trudeau, ancien premier ministre du Canada.",
	},
	{
		id: 7,
		title: "Contact Gold buyers in Beinjin",
		image: "/beijing.jpg",
		des: "Nous menons à celui qui récompense avec des *incitations en espèces* pour les mineurs d'or étrangers dans une sous-société enregistrée à Pékin, et qui achètera leur or en totalité au prix LBMA du jour (pas besoin de réduction !!)",
	},
];
const TrainingData = [
	{
		id:1,
		refNumb:"C01SG",
		type:"Conférence sur Dévelopment  Exponentiel",
		theme: "Sur les fonds hors grand livre : différence entre mythe et réalité",
		droitInsc:"12 000 Euros",
		date: "19 Août 2024",
		lieu:"Géneve / Singapour ",
		dateLimite:"1er Août 2024",
		animateur:"Fondé de pouvoir ",
		des:"",
		image: "/logo.png",
		title:"",
	},
	{
		id:2,
		refNumb:"C02SB",
		type:"Conférence sur Dévelopment  Exponentiel",
		theme: "Cryptographie : IBO, les moyens de mobilisation d'actifs par les banques et la sûreté et la sécurité des actifs cryptographiques.",
		droitInsc:"4 000 Euros ",
		date: "19 Août 2024",
		lieu:"Beijing",
		dateLimite:"1er Août 2024",
		animateur:"Experts en cryptographie et fondé de pouvoir",
		des:"",
		image: "/logo.png",
		title:"",
	},
	{
		id:3,
		refNumb:"C03PH",
		type:"Atelier sur le développement exponentiel alternatif",
		theme: "Cryptographie : cryptomonnaie et financement alternatif du commerce international. Cas de décaissement de 30 millions USDT. ",
		droitInsc:"4 000 Euros ",
		date: "19 Août 2014",
		lieu:"Pékin / Hong Kong ",
		dateLimite:"1er Août 2024",
		animateur:"Experts en cryptographie et propriétaires d'actifs avec la participation des bénéficiaires des prêts accordés ",
		des:"",
		image: "/logo.png",
		title:"",
	},
];
const OpportunityData = [
	
	{
		id:1,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Le soutien d'AFRICA55DURABLE à un nouveau dispositif destiné à toute entreprise étrangère souhaitant s'implanter en Chine.",
		image: "/logo.png",
		title:"Ouvrir une sous-entreprise en Chine",
	},
	{
		id:2,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Ainsi AFRICA55DURABLE récoltera gratuitement des fonds pour vous permettre de développer votre entreprise. Puisque toute collecte de fonds légitime est un service payant. Pour ce faire, les partenaires d'AFRICA55DURABLE paieront les frais d'inscription dès réception de la lettre de procuration qui nous sera adressée.",
		image: "/logo.png",
		title:"Levé de fonds",
	},
];
const HonerData = [
	{
		id:1,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Issu d'une famille pieuse et d'un père catéchiste, il suit une scolarité catholique jusqu'à l'obtention de son baccalauréat. Laurent Kilachu Bado est considéré comme un intellectuel, un homme politique et, à certaines occasions, un humoriste. C’est cette combinaison de profils qui lui a valu sa large audience. Ses discours sont diffusés et rediffusés, notamment par les jeunes Burkinabé, via leurs téléphones portables. Ses discours depuis 1987, aussi controversés soient-ils, sont représentatifs d'une opposition peu contraignante pour le président Blaise Compaoré. Au contraire, ce dernier a su utiliser ses détracteurs pour servir sa légitimité. Le profil de l'opposant Bado est représentatif de l'époque Compaoré.",
		image: "/bado.png",
		title:"Pr. Laurent BADO, l'homme qui vit dans deux siècles",
	},
	{
		id:2,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Ousmane Sonko, né le 15 juillet 1974 à Thiès, est un homme politique sénégalais, Premier ministre depuis le 2 avril 2024. Fondateur en 2014 du parti Patriotes africains du Sénégal pour le travail, l'éthique et la fraternité (PASTEF).  Il fait les frais de sa rigueur et son rejet total de la corruption et des pots de vin. Le peuple s'en convain et vote pour son parti alors qu'il était injustement incarcéré et qu'il avait eu la grandeur d'esprit avec les siens de désigner un autre sage et digne d'Afrique pour le remplacer et porter le flambeau du PASTEF aux élections présidentielles. Sonko appellé à soutenir la candidature de son collègue de travail et ami Bassirou Diomaye Faye qui sera élu président de la République avec 54 % des voix au premier tour. Depuis le mardi 02 avril 2024, il est le Premier Ministre de la République du Sénégal. ",
		image: "/sonko.png",
		title:"Ousmane SONKO, Le champion de la lutte contre la corruption au Sénégal",
	},
	{
		id:3,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Discret, il a pourtant derrière lui des années de médiations et d’influence. Depuis avril 2021, il a pris la tête du Cosim en Côte d’Ivoire avec la lourde mission de contrer la propagation de l’idéologie des groupes terroristes. Son franc-parler, lui, est de notoriété publique. Un épisode a marqué les esprits. Le 21 juin 2017, tout ce que le pays compte de personnalités politiques et d’hommes d’affaires musulmans est réuni à la grande mosquée du quartier huppé de la Riviera Golf. C’est l’affluence des grands jours pour cette Nuit du destin, moment important du mois de ramadan. Ousmane Diakité, alors secrétaire exécutif du Conseil supérieur des imams de Côte d’Ivoire (Cosim), a la parole. Son prêche porte sur l’enrichissement illicite et la corruption. « Si vous détournez des fonds, ne pensez pas que vous avez seulement désobéi à la loi de la République.  ",
		image: "/ousmane.png",
		title:"Ousmane Diakité, l’imam du juste milieu",
	},
	{
		id:4,
		type:"",
		theme: "",
		droitInsc:"",
		date: "",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"Duncan CAMERON : « Depuis une vingtaine d'années, j'ai une longue relation avec l'Afrique, par le biais du Rotary International dans sa quête d'éradication de la polio. Il est triste d'apprendre qu'un certain nombre de cas ont été annoncés la semaine dernière au Nigéria, en raison d'un groupe du nord du Nigéria qui ne permet pas l'accès à la vaccination. La plupart des 40 entreprises avec lesquelles ou pour lesquelles Cameron Group International fait des affaires reversent entre 15 % et 90 % de leurs bénéfices nets aux communautés avec lesquelles nous faisons des affaires. CGI ENGAGE AU MINIMUM 80 % DES PERSONNES DU PAYS DANS LEQUEL NOUS FAISONS DES AFFAIRES. CLIQUEZ SUR LA PAGE TRAVAILLONS AVEC et consultez la liste des entreprises avec lesquelles nous travaillons. Officiellement CGI (Africa) LLC, a été enregistrée à Accra, au Ghana, le 26 mai 2016. Conformément à notre mandat, CGI (Africa) est détenue par des Africains, et comme tous les nouveaux venus, nous avons eu des difficultés de croissance, nous adaptant aux valeurs de CGI et gagner le droit de faire des affaires en Afrique »",
		image: "/duncan.png",
		title:"Duncan CAMERON, l'Afro-Optimiste",
	},
	
];
const AchievementgData = [
	{
		id:1,
		type:"",
		theme: "",
		droitInsc:"",
		date: "dater",
		lieu:"",
		dateLimite:"",
		animateur:"",
		des:"portion in build",
		image: "/logo.png",
		title:"very soon",
	},
];

export { NewsData, TrainingData, HonerData, AchievementgData, OpportunityData };
