// persistConfig.js
import storage from 'redux-persist/lib/storage'; // You can choose the storage engine you prefer
import { persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'root', // Key for storage
  storage, // Use localStorage as the storage engine
    // Add other configuration options as needed
  localStorage,
};

export default persistConfig;
