import React from 'react'
import Top from '../../components/Top'
import AchivementFr from './AchivementFr'
import LayoutFr from '../../components/LayoutFr'


function AchivementsFr() {
  return (
    <LayoutFr>
        <Top />
        <div className='flex flex-col'>
        <AchivementFr />
        </div>
    </LayoutFr>
  )
}

export default AchivementsFr