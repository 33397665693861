import React from 'react'
import Layout from '../../components/Layout'
import Top from '../../components/Top'
import Honor from './Honor'
import { HonerData } from '../../data/data'

function Honors() {
  return (
    <Layout>
    <Top />
    <>
    <div className='flex flex-col'>
    {HonerData &&
					HonerData.map((data, id) => (
						<div key={id}>
							<Honor title={data.title} image={data?.image} des={data?.des} lieu={data?.lieu}/>
              
						</div>
					))}
    </div>
    </>
</Layout>
  )
}

export default Honors