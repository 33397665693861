import React from "react";
import { motion } from "framer-motion";
import Layout from "../../components/Layout";
import Top from "../../components/Top";
import Opportunities from "./Opportunities";

function Opportunite() {
	return (
		<Layout>
			<Top />
			<>
				<motion.div className="flex flex-1 justify-center mt-6 ">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						Opportunities
					</p>
				</motion.div>
				<p className="flex flex-1 text-sm italic text-black font-extralight justify-center mt-5 ">
					We keep you posted on daily motion and trends in the alternative
					development world.
				</p>
				<div className="flex flex-1">
					<Opportunities />
				</div>
				<div className="hidden sm:flex text-black font-extralight text-lg mt-4 justify-center">
					<div className="bg-emerald-300 flex space-x-6 rounded-md py-4 px-6">
						<p className="italic"> send your Email to receive latest posts</p>

						<from>
							<div className="flex items-center space-x-4">
								<input
									type="text"
									placeholder="Your Email please"
									className="bg-white px-2 outline-none"
								/>
								<p className=" bg-teal-600 hover:bg-teal-400  px-4 font-light text-white hover:text-black rounded-full cursor-pointer">
									Send
								</p>
							</div>
						</from>
					</div>
          
				</div>

        <div className="bg-emerald-300 flex flex-col sm:hidden items-center space-y-6 rounded-md py-4 mt-6 px-6">
					<p className="italic text-lg">
						{" "}
						send your Email to receive latest posts
					</p>

					<from className="text-lg">
						<div className="flex items-center space-x-4">
							<input
								type="text"
								placeholder="Your Email please"
								className="bg-white px-2 outline-none"
							/>
							<p className=" bg-teal-600 hover:bg-teal-400  px-4 font-light text-white hover:text-black rounded-full cursor-pointer">
								Send
							</p>
						</div>
					</from>
				</div>
			</>
		</Layout>
	);
}

export default Opportunite;
