import React from "react";
import Hero from "./Hero";
import Intro from "./Intro";
import Mywhy from "./Mywhy";
import Layout from "../../components/Layout";
import MywhyTop from "./MywhyTop";


function Accueil() {
	return (
		<Layout>
			<Hero />
			<MywhyTop />
			<Intro />
			<Mywhy />
		</Layout>
	);
}

export default Accueil;
