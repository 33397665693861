import React from "react";
import "./Hero.css";
import { useState } from "react";
import { sliderData } from "./sliderFr-data";
import { useEffect } from "react";

function HeroFr() {
	const [currentSlide, setCurrentSlide] = useState(0);
	const slideLength = sliderData.length;

	const autoScroll = true;
	let slideInterval;
	let intervalTime = 9000;

	const nextSlide = () => {
		setCurrentSlide(currentSlide === slideLength - 1 ? 0 : currentSlide + 1);
		console.log("next");
	};

	const prevSlide = () => {
		setCurrentSlide(currentSlide === 0 ? slideLength - 1 : currentSlide - 1);
		console.log("prev");
	};

	function auto() {
		slideInterval = setInterval(nextSlide, intervalTime);
	}

	useEffect(() => {
		setCurrentSlide(0);
	}, []);

	useEffect(() => {
		if (autoScroll) {
			auto();
		}
		return () => clearInterval(slideInterval);
	}, [currentSlide]);

	return (
		<div className="background mt-6 ">
			{/* carousel*/}
			<div className="carousel bg-slate-200">
				{/*<!-- list item -->*/}
				<div className="list">
					{sliderData.map((slide, index) => {
						return (
							<div
								className={index === currentSlide ? "slide current" : "slide"}
								key={index}>
								{index === currentSlide && (
									<div className="item">
										<img
											src={slide.image}
											alt=""
											className="hidden sm:inline-block sm:w-[500px] w-[200px]"
										/>

										<img
											src={slide.image1}
											alt=""
											className="sm:hidden inline-block sm:w-[500px] w-[200px]"
										/>
										<div className="content">
											<div className="author mt-10">{slide.author}</div>
											<div className="title text-rose-800">{slide.title}</div>
											<div className="topic1 sm:topic "> {slide.topic}</div>
											<div className="des mt-3 text-lg text-black bg-slate-50/60 p-2 w-fit">
												{/*<!-- lorem 50 -->*/}
												{slide.desc}
											</div>
											<div className="buttons">
												<button className=" hover:bg-transparent hover:text-black text-red-500">
													Voir +
												</button>
												<button className="  text-gray-950 hover:bg-white hover:text-black ">
													Info
												</button>
											</div>
										</div>
									</div>
								)}
							</div>
						);
					})}
				</div>

				{/*<!-- next prev -->*/}

				<div className="arrows">
					<button
						id="prev"
						onClick={prevSlide}
						className="hidden sm:flex justify-center bg-white/70">
						Prs
					</button>
					<button
						id="next"
						onClick={nextSlide}
						className="hidden sm:flex justify-center bg-white/70">
						Svt
					</button>
				</div>
				{/*<!-- time running -->*/}
				<div className="time"></div>
			</div>
		</div>
	);
}

export default HeroFr;
