import React from "react";
import image from "../../img/child.png";
import image2 from "../../img/img11.jpg";
import { motion } from "framer-motion";

function IntroAbout() {
	return (
		<>
			<div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 items-center  ">
					<div className=" bg-emerald-800 p-6 sm:max-w-40 sm:h-[400px] ">
						<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
					</div>
					<img
						src={image}
						alt=""
						className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
					/>
				</motion.div>
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						To know about us
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-cyan-600">
						Presenting our Champion Ousmane DIAKITE "the Africanophile"
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-black">
						We call him " <b>Mr. Africa</b>".
					</p>
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
						He wastes no time in making his passion for the development of
						Africa understood. Ousmane DIAKITE has advanced studies in economic
						policy management. He will very quickly carve out a profile as an
						expert in economic policy programming.
						<br />
						Above all, Ousmane DIAKITE, Mr. Africa, is an authorized
						representative of fund owners. It is accredited by certain world's
						top 50 banking and financial institutions. And he justifies several
						project financings around the world.
						<br />
						Ousmane DIAKITE or "El professor" provides lessons in alternative
						financing and development. It provides economic policy consultations
						and recommendations to governmental and non- governmental entities.
					</p>
				</motion.div>
			</div>
			{/*--mobile screen--*/}

			<div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
				<motion.div
					initial={{ y: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, y: 0 }}
					className="flex flex-1 flex-col text-black mt-4">
					<p className=" border-b-8 border-yellow-900 pb-2 text-2xl w-fit text-orange-500">
						To know about us
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-cyan-600">
						Presenting our Champion Ousmane DIAKITE "the Africanophile"
					</p>
					<p className="flex text-lg mt-2 font-light ml-4 text-black space-x-4">
						We call him " <b>Mr. Africa</b>".
					</p>
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
						He wastes no time in making his passion for the development of
						Africa understood. Ousmane DIAKITE has advanced studies in economic
						policy management. He will very quickly carve out a profile as an
						expert in economic policy programming.
						<br />
						Above all, Ousmane DIAKITE, Mr. Africa, is an authorized
						representative of fund owners. It is accredited by certain world's
						top 50 banking and financial institutions. And he justifies several
						project financings around the world.
						<br />
						Ousmane DIAKITE or "El professor" provides lessons in alternative
						financing and development. It provides economic policy consultations
						and recommendations to governmental and non- governmental entities.
					</p>
				</motion.div>
			</div>

			<motion.div
				initial={{ y: 200, opacity: 0 }}
				transition={{ duration: 1 }}
				whileInView={{ opacity: 1, y: 0 }}
				className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
				<div className=" flex flex-col bg-yellow-900  w-[400px] h-[260px] justify-center "></div>
				<img
					src={image}
					alt=""
					className="w-[380px] h-[220px] object-cover  absolute "
				/>
			</motion.div>

			{/*--Organization--*/}

			<div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 flex-col text-black">
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
						He benefits from several certificates of satisfaction for the
						teaching and supervision provided. He has to his credit several
						certificates of good reputation and good morality in the management
						of international non-governmental organizations that numerous vices
						constantly lie in wait and threaten. Ousmane DIAKITE is an expert in
						eco diplomacy.
						<br /> He was a very senior, highly qualified embassy executive,
						renowned in his functions as Head of the Economic Service and the
						promotion of bilateral trade. Ousmane DIAKITE is discreet. Many
						experts and executives around the world walk in his shadow.
						<br />
						Ousmane DIAKITE occupied and served the back office of the offices
						of special advisor to the prime minister and cabinet of the
						presidency of the Republic. Ousmane DIAKITÉ remains grateful to all
						his teachers and trainers.
					</p>
				</motion.div>

				<motion.div
					initial={{ x: -200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="flex flex-1 items-center  ">
					<div className=" bg-yellow-900 p-6 sm:max-w-40 sm:h-[400px] ">
						<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
					</div>
					<img
						src={image}
						alt=""
						className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
					/>
				</motion.div>
			</div>
			{/*--mobile screen--*/}

			<div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
				<motion.div
					initial={{ y: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, y: 0 }}
					className="flex flex-1 flex-col text-black mt-4">
					<p className="flex text-lg mt-2 font-extralight ml-4 text-justify">
						He benefits from several certificates of satisfaction for the
						teaching and supervision provided. He has to his credit several
						certificates of good reputation and good morality in the management
						of international non-governmental organizations that numerous vices
						constantly lie in wait and threaten.
						<br />
						Ousmane DIAKITE is an expert in eco diplomacy. He was a very senior,
						highly qualified embassy executive, renowned in his functions as
						Head of the Economic Service and the promotion of bilateral trade.
						<br />
						Ousmane DIAKITE is discreet. Many experts and executives around the
						world walk in his shadow. Ousmane DIAKITE occupied and served the
						back office of the offices of special advisor to the prime minister
						and cabinet of the presidency of the Republic. Ousmane DIAKITÉ
						remains grateful to all his teachers and trainers.
						<br />
					</p>
				</motion.div>
			</div>

			<motion.div
				initial={{ y: 200, opacity: 0 }}
				transition={{ duration: 1 }}
				whileInView={{ opacity: 1, y: 0 }}
				className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
				<div className=" flex flex-col bg-yellow-900  w-[400px] h-[260px] justify-center "></div>
				<img
					src={image2}
					alt=""
					className="w-[380px] h-[220px] object-cover  absolute "
				/>
			</motion.div>
		</>
	);
}

export default IntroAbout;
