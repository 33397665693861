import React, { useState } from "react";
import Layout from "../../components/Layout";
import { BsArrowLeftSquare } from "react-icons/bs";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";
import { motion } from "framer-motion";
import Top from "../../components/Top";
import Posts from "./Posts";

function News() {
	const [email, setEmail] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		//keys and services
		const templateId = "template_k06u98r";
		const publicKey = "vRKpOxPaAllPvKD00";
		const serviceId = "service_1icj9w8";

		//create new object

		const templateParams = {
			to_name: "Africa55Durable Email News request",
			from_email: email,
		};

		//send theEmail
		emailjs
			.send(serviceId, templateId, templateParams, publicKey)
			.then((response) => {
				console.log("Message successfully sent", response);
				setEmail("");
			})
			.catch((error) => {
				console.error("Error sending", error);
			});

		//////////
		const refreshToast = toast.loading(
			"Your message has been sent. We will shortly contact you"
		);
		toast.success("Your message has been sent. We will shortly contact you", {
			id: refreshToast,
			duration: 7000,
		});

		/// navigate to
	};

	return (
		<Layout>
			<Top />
			<Toaster />
			<>
				<motion.div className="flex flex-1 justify-center mt-6 ">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						AFRICA55DURABLE Breaking News
					</p>
				</motion.div>
				<p className=" flex flex-1 text-xl italic text-black font-extralight justify-center mt-5 text-center ">
					We keep you posted on daily motion and trends in the alternative
					development world.
				</p>

				<div className="flex flex-col">
					<p className="flex flex-1 space-x-4 items-center">
						<BsArrowLeftSquare className="w-10 h-4 text-black z-10" />
						<p className="text-sm italic font-thin text-slate-400 ">
							Slide left pictures to see more information
						</p>
					</p>
					<Posts />
				</div>
				<div className="flex text-black font-extralight text-lg mt-4 justify-center">
					<div className="bg-emerald-300 hidden sm:flex space-x-6 rounded-md py-4 px-6">
						<p className="italic"> send your Email to receive latest posts</p>

						<from>
							<div className="flex items-center space-x-4">
								<input
									type="text"
									placeholder="Your Email please"
									className="bg-white px-2 outline-none"
									onChange={(e) => setEmail(e.target.value)}
								/>
								<p className=" bg-teal-600 hover:bg-teal-400  px-4 font-light text-white hover:text-black rounded-full cursor-pointer">
									Send
								</p>
							</div>
						</from>
					</div>

					<div className="bg-emerald-300 flex flex-col sm:hidden items-center space-y-6 rounded-md py-4 px-6">
						<p className="italic"> send your Email to receive latest posts</p>

						<from onSubmit={handleSubmit} className="">
							<div className="flex items-center space-x-4 ">
							<input
									type="text"
									placeholder="Your Email please"
									className="bg-white px-2 outline-none"
									onChange={(e) => setEmail(e.target.value)}
								/>
								<p className=" bg-teal-600 hover:bg-teal-400  px-4 font-light text-white hover:text-black rounded-full cursor-pointer">
									Send
								</p>
							</div>
						</from>
					</div>
				</div>
			</>
		</Layout>
	);
}

export default News;
