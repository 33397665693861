import React from 'react'
import Top from '../../components/Top'
import { HonerData } from '../../data/dataFr'
import LayoutFr from '../../components/LayoutFr'
import HonorFr from './HonorFr'

function HonorsFr() {
  return (
    <LayoutFr>
    <Top />
    <>
    <div className='flex flex-col'>
    {HonerData &&
					HonerData.map((data, id) => (
						<div key={id}>
							<HonorFr title={data.title} image={data?.image} des={data?.des} lieu={data?.lieu}/>
              
						</div>
					))}
    </div>
    </>
</LayoutFr>
  )
}

export default HonorsFr