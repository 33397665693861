import React from "react";
import Layout from "../../components/Layout";
import Top from "../../components/Top";
import emailjs from '@emailjs/browser'
import { Toaster, toast } from "react-hot-toast";
import { motion } from "framer-motion";
import { useState } from "react";

function Member() {
	const [nomPrenoms, setNomPrenoms] = useState("");
	const [fonction, setFonction] = useState("");
	const [email, setEmail] = useState("");
	const [numeroWhatsapp, setNumeroWhatsapp] = useState("");
	const [domaineActivite, setDomaineActivite] = useState("");


	

	const handleSubmit = (e) => {
		e.preventDefault()
		//keys and services
		const templateId ='template_k06u98r'
		const publicKey = 'vRKpOxPaAllPvKD00'
		const serviceId = 'service_1icj9w8' 
	
		//create new object
	
		const templateParams ={
		  to_name:'Africa55Durable Membership request',
		  from_name:nomPrenoms,
		  from_email:email,
		  from_company:fonction,
		  from_number:numeroWhatsapp,
		  from_activity:domaineActivite,
		}
		
		//send theEmail
		emailjs.send(serviceId, templateId, templateParams, publicKey)
		.then((response)=>{
		  console.log('Message successfully sent', response)
		  setEmail('')
		  setNomPrenoms('')
		  setNumeroWhatsapp('')
		  setFonction('')
		  setDomaineActivite('')

		})
		.catch((error)=>{
		  console.error('Error sending', error)
		})
	
		//////////
		const refreshToast = toast.loading("Your message has been sent. We will shortly contact you");
				toast.success("Your message has been sent. We will shortly contact you", {
					id: refreshToast,
			duration: 7000,
				});
	
		  /// navigate to
	  }

	return (
		<Layout>
			<Top />
			<div className="flex flex-1 justify-center my-6 bg-white">
				<motion.div
					initial={{ x: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, x: 0 }}
					className="text-black bg-green-100 p-4">
					<p className="border-b-8 text-orange-500 border-emerald-800 pb-3 text-2xl bg-white px-3 backdrop-blur-md text-center">
						Please kindly fill and send the form below to apply
					</p>
					<Toaster />
					<p className=" my-6 backdrop-blur-md">
						<form onSubmit={handleSubmit}>
							<div className="flex flex-col space-y-4">
								<ul className="flex space-x-4 text-lg ">
									<li className="w-40">Full Name:</li>
									<li>
										{" "}
										<input
											type="text"
											placeholder=""
											name={nomPrenoms}
											value={nomPrenoms}
											onChange={(e) => setNomPrenoms(e.target.value)}
											className=" bg-white/80 outline-none py-1 px-3 text-black"
										/>{" "}
									</li>
								</ul>

								<ul className="flex space-x-4 text-lg">
								<li className="w-40">Position:</li>
									<li>
										{" "}
										<input
											type="text"
											placeholder=""
											name={fonction}
											value={fonction}
											onChange={(e) => setFonction(e.target.value)}
											className=" bg-white/80 outline-none py-1 px-3 text-black"
										/>{" "}
									</li>
								</ul>

								<ul className="flex space-x-4 text-lg">
								<li className="w-40">Email:</li>
									<li>
										{" "}
										<input
											type="text"
											placeholder=""
											name={email}
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											className=" bg-white/80 outline-none py-1 px-3 text-black"
										/>{" "}
									</li>
								</ul>

								<ul className="flex space-x-4 text-lg">
								<li className="w-40">Phone Number</li>
									<li>
										{" "}
										<input
											type="text"
											placeholder=""
											name={numeroWhatsapp}
											value={numeroWhatsapp}
											onChange={(e) => setNumeroWhatsapp(e.target.value)}
											className=" bg-white/80 outline-none py-1 px-3 text-black"
										/>{" "}
									</li>
								</ul>

								<ul className="flex space-x-4 text-lg">
								<li className="w-40">Area of activity </li>
									<li>
										{" "}
										<input
											type="text"
											placeholder=""
											name={domaineActivite}
											value={domaineActivite}
											onChange={(e) => setDomaineActivite(e.target.value)}
											className=" bg-white/80 outline-none py-1 px-3 text-black"
										/>{" "}
									</li>
								</ul>

							</div>
							
								<p
								onClick={handleSubmit}
								 className="px-6 py-2 text-blue-600 rounded-md bg-green-300 justify-center text-xl flex my-4 cursor-pointer hover:bg-green-500">Send</p>
							
						</form>
					</p>
				</motion.div>
			</div>
		</Layout>
	);
}

export default Member;
