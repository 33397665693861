// store.js
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './rootReducer'; // Your rootReducer
import persistConfig from './persistConfig'; // The persistConfig you created
import { persistStore } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';


const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
});

const persistor = persistStore(store);

export { store, persistor };
