import React from "react";
import { motion } from "framer-motion";


function HonorFr({image, des, title, date, lieu}) {
	return (
		<>
			<div className="flex flex-1 flex-col w-full">
				<div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
					<motion.div
						initial={{ x: -200, opacity: 0 }}
						transition={{ duration: 1 }}
						whileInView={{ opacity: 1, x: 0 }}
						className="flex flex-1 items-center  ">
						<div className=" bg-emerald-800 p-6 sm:max-w-40 sm:h-[400px] ">
							<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
						</div>
						<img
							src={image}
							alt=""
							className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
						/>
					</motion.div>
					<motion.div
						initial={{ x: 200, opacity: 0 }}
						transition={{ duration: 1 }}
						whileInView={{ opacity: 1, x: 0 }}
						className="flex flex-1 flex-col text-black">
						<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						{title}
						</p>
						<p className="flex flex-1 text-sm italic text-black font-extralight  mt-2 ">
							{date}{" "}  {" "} {lieu}
						</p>
						<p className="flex text-lg font-extralight ml-4 text-justify">
							{des}
						</p>
					</motion.div>
				</div>
				<div className=" hidden sm:flex bg-cyan-200 flex-1 w-full h-1 text-cyan-200">
					gtrr{" "}
				</div>
			</div>
			{/*--mobile screen--*/}

			<div className="flex flex-1 flex-col">
				<div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
					<motion.div
						initial={{ y: 200, opacity: 0 }}
						transition={{ duration: 1 }}
						whileInView={{ opacity: 1, y: 0 }}
						className="flex flex-1 flex-col text-black mt-4">
						<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						{title}
						</p>
						<p className="flex flex-1 text-sm italic text-black font-extralight  mt-2 ">
						{date}{" "} et {" "} {lieu}
						</p>
						<p className="flex text-lg mt-2 font-extralight ml-4">
						{des}
						</p>
					</motion.div>
				</div>

				<motion.div
					initial={{ y: 200, opacity: 0 }}
					transition={{ duration: 1 }}
					whileInView={{ opacity: 1, y: 0 }}
					className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
					<div className=" flex flex-col bg-emerald-800  w-[400px] h-[260px] justify-center "></div>
					<img
						src={image}
						alt=""
						className="w-[380px] h-[220px] object-cover  absolute "
					/>
				</motion.div>
				<div className="flex sm:hidden text-xs bg-cyan-200 flex-1 w-full h-[2px] text-cyan-200 mt-5">
					gtrr{" "}
				</div>
			</div>
		</>
	);
}

export default HonorFr;
