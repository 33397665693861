import React from "react";
import { TrainingData } from "../../data/dataFr";
import TrainFr from "./TrainFr";

function TrainsFr() {
	return (
		<>
			<div className="sm:flex w-full overflow-x-auto items-center flex-shrink-0 overflow-hidden  scrollbar-hide space-x-4 px-4 sm:px-0">
				{TrainingData &&
					TrainingData.map((data, id) => (
						<div key={id}>
							<TrainFr
								type={data.type}
								theme={data.theme}
								image={data.image}
								animateur={data.animateur}
								droitInsc={data.droitInsc}
								lieu={data.lieu}
								dateLimite={data.dateLimite}
								date={data.date}
								refNumb={data.refNumb}
							/>
						</div>
					))}
			</div>
		</>
	);
}

export default TrainsFr;
