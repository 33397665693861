import React, { useState } from "react";
import { BsArrowLeftSquare } from "react-icons/bs";
import { motion } from "framer-motion";
import Top from "../../components/Top";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";
import PostsFr from "./PostsFr";
import LayoutFr from "../../components/LayoutFr";

function NewsFr() {
	const [email, setEmail] = useState("");

	const handleSubmit = (e) => {
		e.preventDefault();
		//keys and services
		const templateId = "template_k06u98r";
		const publicKey = "vRKpOxPaAllPvKD00";
		const serviceId = "service_1icj9w8";

		//create new object

		const templateParams = {
			to_name: "Africa55Durable Email News request",
			from_email: email,
		};

		//send theEmail
		emailjs
			.send(serviceId, templateId, templateParams, publicKey)
			.then((response) => {
				console.log("Message successfully sent", response);
				setEmail("");
			})
			.catch((error) => {
				console.error("Error sending", error);
			});

		//////////
		const refreshToast = toast.loading(
			"Votre message a été envoyé! notre équipe vous contactera."
		);
		toast.success("Votre message a été envoyé! notre équipe vous contactera", {
			id: refreshToast,
			duration: 7000,
		});

		/// navigate to
	};

	return (
		<LayoutFr>
			<Top />
			<Toaster />
			<>
				<motion.div className="flex flex-1 justify-center mt-6 ">
					<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
						AFRICA55DURABLE Info
					</p>
				</motion.div>
				<p className=" flex flex-1 text-xl italic text-black font-extralight justify-center mt-5 text-center ">
					Nous vous tenons au courant des mouvements quotidiens et des tendances
					dans le monde du développement alternatif.
				</p>

				<div className="flex flex-col">
					<p className="flex flex-1 space-x-4 items-center">
						<BsArrowLeftSquare className="w-10 h-4 text-black z-10" />
						<p className="text-sm italic font-thin text-slate-400 ">
							Faites glisser les image pour voir plus d'information
						</p>
					</p>
					<PostsFr />
				</div>
				<div className="flex text-black font-extralight text-lg mt-4 justify-center">
					<div className="bg-emerald-300 hidden sm:flex space-x-6 rounded-md py-4 px-6">
						<p className="italic"> Envoyer votre Email pour rester informer</p>

						<from onSubmit={handleSubmit}>
							<div className="flex items-center space-x-4">
								<input
									type="text"
									placeholder="Your Email please"
									className="bg-white px-2 outline-none"
								/>
								<p className=" bg-teal-600 hover:bg-teal-400  px-4 font-light text-white hover:text-black rounded-full cursor-pointer">
									Envoyer
								</p>
							</div>
						</from>
					</div>

					<div className="bg-emerald-300 flex flex-col sm:hidden items-center space-y-6 rounded-md py-4 px-6">
						<p className="italic"> Envoyer votre Email pour rester informers</p>

						<from onSubmit={handleSubmit} className="text-black">
							<div className="flex items-center space-x-4 text-black">
								<input
									type="email"
									placeholder="Email"
									className="w-full m-2    p-2 outline-none  text-black rounded-md"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
								<p className=" bg-teal-600 hover:bg-teal-400  px-4 font-light text-white hover:text-black rounded-full cursor-pointer">
									Envoyer
								</p>
							</div>
						</from>
					</div>
				</div>
			</>
		</LayoutFr>
	);
}

export default NewsFr;
