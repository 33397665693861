const sliderData = [
  {
    image: "image/car8.png",
    image1: "image/scar8.png",
    heading: "Slide One",
    title:"Repondre au défi énergétique de l'Afrique",
    topic:"avec NEUTRINO ENERGY GROUP",
    seeLink:"",
    subLink:"",
    desc: "Un partenariat pour répondre aux besoins énergétiques croissants de l’Afrique",
  },
  {
    image: "image/car1.png",
    image1: "image/scar1.png",
    heading: "Slide One",
    title:"Sécurier l'Afrique",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Sécuriser les frontières des 55 Pays d'Africa",
  },

  {
    image: "image/car2.png",
    image1: "image/scar2.png",
    heading: "Slide One",
    title:"Eliminer la corruption",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Lutter contre la corruption et la fraude",
  },

  {
    image: "image/car4.png",
    image1: "image/scar4.png",
    heading: "Slide One",
    title:"Construire l'Afrique verte",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Construction de 55 villes et villages verts à travers l'Afrique",
  },

  {
    image: "image/car3.png",
    image1: "image/scar3.png",
    heading: "Slide One",
    title:"Education",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Education et santé verte",
  },
  
  {
    image: "image/car5.png",
    image1: "image/scar5.png",
    heading: "Slide One",
    title:"Industrialiser l'Afrique",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Industrialisation de l'Africa et des énergies vertes",
  },

  {
    image: "image/car6.png",
    image1: "image/scar6.png",
    heading: "Slide One",
    title:"FinTech et NFT",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "FinTech Blockchain Banking NFT cryptographie SBLC tokenisation des ressources naturelles et des avoirs",
  },

  {
    image: "image/car7.png",
    image1: "image/scar7.png",
    heading: "Slide One",
    title:"Réduction de la dette",
    topic:"Avec Africa55Durable",
    seeLink:"",
    subLink:"",
    desc: "Travailler à réduire la dette de pays africains",
  },
];


const etechData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];


const pieceData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];

const accessoireData = [
  {
    image: "image/car1.png",
    heading: "Slide One",
    title:"Véhicule utilitaires",
    topic:"SUV",
    seeLink:"",
    subLink:"",
    desc: "Nous avons des SUV de toute marque, de qualité et d'origine avec garanti disponible pour toutes les bourses ",
  },

  {
    image: "image/car2.png",
    heading: "Slide One",
    title:"TRANSPORT DE BIENS",
    topic:"MARCHANDISES",
    seeLink:"",
    subLink:"",
    desc: "Le transport de biens et marchandises vous impose d'avoir de véhicules robustes. Vous trouvez ces véhicules de toute marque avec nous",
  },

  {
    image: "image/car4.png",
    heading: "Slide One",
    title:"Pick-Up",
    topic:"D'ORIGINE",
    seeLink:"",
    subLink:"",
    desc: "Nous metons à votre disposition des véhicules de types 4X4 et Pick-Up, robustes et adaptés pour nos routes. ",
  },

  {
    image: "image/car3.png",
    heading: "Slide One",
    title:"TRANSPORT DE",
    topic:"PERSONNEL",
    seeLink:"",
    subLink:"",
    desc: "Parce que votre personnel est votre force, nous metons à votre disposition des Cars et des mini-cars de toute marque.",
  },
  
];

export {sliderData, etechData, pieceData, accessoireData}