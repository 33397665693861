import React from 'react'
import Layout from '../../components/Layout'
import { motion } from "framer-motion";
import Top from '../../components/Top'
import { useDispatch, useSelector } from 'react-redux';
import { closeArticle, selectOpenArticle } from '../../features/articleSlice';
import { useNavigate } from 'react-router-dom';

function ViewPost() {

	const selectedArticle = useSelector(selectOpenArticle)
	const dispatch = useDispatch()
	const navigate = useNavigate()

	const closePost = () => {
        dispatch(closeArticle())
        navigate('/news')
  }


  return (
    <Layout>
        <Top />
        <>
        <div className="hidden sm:flex flex-1 justify-between mt-6 sm:px-24 sm:space-x-3 bg-white p-4 ">
			<motion.div 
            initial={{x: -200, opacity:0}}
            transition={{duration:1}}
            whileInView={{opacity:1, x:0}}
            className="flex flex-1 items-center  ">
				<div className=" bg-emerald-800 p-6 sm:max-w-40 sm:h-[400px] ">
					<div className=" bg-white sm:max-w-40 sm:h-[350px] sm:w-60"></div>
				</div>
				<img
					src={selectedArticle?.image}
					alt=""
					className="sm:w-[480px] sm:h-[320px] object-cover absolute ml-10 "
				/>
			</motion.div>
			<motion.div
            initial={{x: 200, opacity:0}}
            transition={{duration:1}}
            whileInView={{opacity:1, x:0}} 
            className="flex flex-1 flex-col text-black">
				<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
					{selectedArticle?.title}
				</p>
				<p className="flex text-lg text-justify mt-2 font-extralight ml-4">
				{selectedArticle?.des}
				</p>
				<p onClick={closePost} className='mt-5 bg-green-400 font-light cursor-pointer text-black px-3 py-1 rounded-full flex  justify-center text-lg w-fit'>Back to News</p>
			</motion.div>
		</div>
        {/*--mobile screen--*/}

        <div className="flex flex-col sm:hidden px-4 flex-1 space-y-4 mt-6 justify-center  ">
			
			<motion.div
            initial={{y: 200, opacity:0}}
            transition={{duration:1}}
            whileInView={{opacity:1, y:0}} 
            className="flex flex-1 flex-col text-black mt-4">
				<p className=" border-b-8 border-emerald-800 pb-2 text-2xl w-fit text-orange-500">
					{selectedArticle?.title}
				</p>
				<p className="flex text-lg mt-2 font-extralight ml-4">
				{selectedArticle?.des}
				</p>
			</motion.div>
		</div>

        <motion.div 
          initial={{y: 200, opacity:0}}
          transition={{duration:1}}
          whileInView={{opacity:1, y:0}}
        className="flex flex-col flex-1 items-center  justify-center sm:hidden ">
				<div className=" flex flex-col bg-emerald-800  w-[400px] h-[260px] justify-center ">
					
				</div>
				<img
					src={selectedArticle?.image}
					alt=""
					className="w-[380px] h-[220px] object-cover  absolute "
				/>
			<p onClick={closePost} className='mt-5 bg-green-400 font-light cursor-pointer text-black px-3 py-1 rounded-full flex  justify-center text-lg w-fit'>Back to News</p>
			</motion.div>
        </>
    </Layout>
  )
}

export default ViewPost